<template>
  <div class="join">
    <img src="../../assets/img/join_bg.png" alt="" />
    <el-row :gutter="20">
      <el-col :span="4"><div class="blank_box"></div></el-col>
      <el-col :span="16">
        <div class="content container">
          <el-row :gutter="20">
            <el-col :span="24">
              <p class="join_title">业务咨询</p>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <p class="join_tips">请填写以下表单，我们将尽快与您联系</p>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form
                label-position="top"
                :model="join_form"
                :rules="join_rules"
                ref="join_form"
                class="form"
              >
                <el-form-item label="联系姓名" prop="name">
                  <el-input
                    v-model="join_form.name"
                    placeholder="请输入联系姓名"
                    :maxlength="10"
                  ></el-input>
                </el-form-item>
                <el-form-item label="业务咨询类型" prop="business_type">
                  <el-select
                    v-model="join_form.business_type"
                    placeholder="请选择业务咨询类型"
                  >
                    <el-option
                      v-for="item in join_type_options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="企业名称">
                  <el-input
                    v-model="join_form.company_name"
                    placeholder="请输入企业名称"
                    :maxlength="20"
                  ></el-input>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="12">
              <el-form
                label-position="top"
                :model="join_form"
                :rules="join_rules"
                ref="join_form_01"
                class="form"
              >
                <el-form-item label="联系方式" prop="phone">
                  <el-input
                    v-model="join_form.phone"
                    placeholder="请输入联系方式"
                    :maxlength="11"
                  ></el-input>
                </el-form-item>
                <el-form-item label="企业所在地">
                  <el-cascader
                    size="large"
                    :options="options"
                    v-model="join_form.company_area"
                    @change="addressChange"
                  >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="企业类型">
                  <el-select
                    v-model="join_form.company_type"
                    placeholder="请选择企业类型"
                  >
                    <el-option
                      v-for="item in company_type_options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form
                label-position="top"
                :model="join_form"
                :rules="join_rules"
                ref="join_form"
                class="form"
              >
                <el-form-item label="简要说明您的需求">
                  <el-input
                    v-model="join_form.content"
                    placeholder="请描述您的具体需求"
                    type="textarea"
                    :maxlength="255"
                  ></el-input>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <button class="key_button submit" @click="submit('join_form')">
                提交
              </button>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="4"><div class="blank_box"></div></el-col>
    </el-row>
  </div>
</template>
<script>
import { regionData, CodeToText } from "element-china-area-data";
import { information } from "@/https/api.js";
export default {
  data() {
    return {
      join_form: {
        content: "",
        name: "",
        phone: "",
        business_type: "",
        company_name: "",
        company_type: "",
        company_area: [],
      },
      join_rules: {
        phone: [
          {
            required: true,
            trigger: "blur",
            type:'number',
            validator: (rule, value, callback) => {
              if (value) {
                let regx = new RegExp(/^[1][3,4,5,7,8,9][0-9]{9}$/);
                if (regx.test(value)) {
                  return callback();
                }
                return callback(new Error("电话号码格式错误"));
              }
              return callback(new Error("请填写联系方式"));
            },
          },
        ],
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请填写联系姓名",
          },
        ],
        business_type: [
          {
            required: true,
            trigger: "blur",
            message: "请选择业务咨询类型",
          },
        ],
      },
      join_type_options: [
        {
          label: "软件定制开发",
          value: "软件定制开发",
        },
        {
          label: "智慧小区改造",
          value: "智慧小区改造",
        },
        {
          label: "智慧停车",
          value: "智慧停车",
        },
      ],
      company_type_options: [
        {
          label: "硬件工厂",
          value: "硬件工厂",
        },
        {
          label: "物业公司",
          value: "物业公司",
        },
        {
          label: "房产开发商",
          value: "房产开发商",
        },
        {
          label: "地产集成商",
          value: "地产集成商",
        },
        {
          label: "软件渠道商",
          value: "软件渠道商",
        },
      ],
      options: regionData,
    };
  },
  mounted() {
    this.resetForm("join_form");
    this.resetForm("join_form_01");
    window.scrollTo(0, 0);
  },
  methods: {
    submit(formName) {
      this.$refs["join_form"].validate((valid) => {
        if (valid) {
          this.$refs["join_form_01"].validate((el) => {
            if (el && this.join_form.name && this.join_form.business_type) {
              information({
                ...this.join_form,
                company_area: this.join_form.company_area.join(","),
              }).then((res) => {
                if (res.code === 0) {
                  this.$alert("您的咨询信息已提交!", "提示", {
                    confirmButtonText: "确定",
                  });
                  this.resetForm(formName);
                  this.resetForm("join_form_01");
                  this.join_form = {};
                  this.join_form.company_area = [];
                } else {
                  alert(`${res.msg}`);
                }
              });
            } else if (
              el &&
              !this.join_form.name &&
              this.join_form.business_type
            ) {
              this.$alert("请填写姓名", "提示", {
                confirmButtonText: "确定",
              });
            } else if (
              el &&
              this.join_form.name &&
              !this.join_form.business_type
            ) {
              this.$alert("请选择咨询类型", "提示", {
                confirmButtonText: "确定",
              });
            } else if (!this.join_form.name && !this.join_form.business_type) {
              this.$alert("您填写的信息不完整", "提示", {
                confirmButtonText: "确定",
              });
            }
          });
        } else {
          this.$alert("您填写的信息不完整", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    addressChange(arr) {
      console.log(arr);
      console.log(CodeToText[arr[0]], CodeToText[arr[1]], CodeToText[arr[2]]);
    },
  },
};
</script>
<style lang="less" scoped>
.join {
  position: relative;
  top: 80px;
  margin-bottom: 28px;
  min-width: 1200px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 96.15%;
    z-index: 130;
  }
  .content {
    height: 1096px;
    background-color: #fff;
    padding: 80px;
    position: relative;
    z-index: 135;
    box-shadow: 0px 10px 29px 2px rgba(15, 15, 15, 0.12);
    margin: 150px auto;
    .join_title {
      font-size: 48px;
      color: #4d4d4d;
    }
    .join_tips {
      font-size: 20px;
      color: #666666;
      position: relative;
      top: -20px;
    }
    /deep/ .el-form-item__label {
      width: 100%;
      text-align: left;
      font-size: 28px;
      color: rgba(102, 102, 102, 1);
      padding-left: 10px;
    }
    .form {
      position: relative;
      top: -50px;
      /deep/ .el-input__inner {
        height: 60px;
        width: 496px;
      }
      /deep/ .el-form-item {
        padding-bottom: 45px;
      }
    }
    .key_button {
      position: relative;
      top: -75px;
      width: 100%;
      height: 95px;
    }
  }
}
@media screen and(max-width:1200px) {
  .join {
    top: 60px;
    > img {
      height: 96.9%;
    }
    .content {
      height: 750px;
      width: 800px;
      padding: 30px;
      .join_title {
        font-size: 26px;
      }
      .join_tips {
        font-size: 16px;
        top: -45px;
      }
      /deep/ .el-form-item__label {
        font-size: 18px;
        padding-left: 5px;
      }
      .form {
        top: -80px;
        /deep/ .el-input__inner {
          height: 40px;
          width: 340px;
        }
        /deep/ .el-form-item {
          padding-bottom: 0;
        }
      }
      .key_button {
        top: -70px;
        height: 45px;
      }
    }
  }
}
@media screen and(min-width:1200.1px) and(max-width:1500px) {
  .join {
    top: 80px;
    > img {
      height: 95.8%;
    }
    .content {
      height: 888px;
      width: 1000px;
      padding: 45px;
      .join_title {
        font-size: 35px;
      }
      .join_tips {
        font-size: 18px;
        top: -35px;
      }
      /deep/ .el-form-item__label {
        font-size: 20px;
        padding-left: 10px;
      }
      .form {
        top: -65px;
        /deep/ .el-input__inner {
          height: 50px;
          width: 410px;
        }
        /deep/ .el-form-item {
          padding-bottom: 20px;
        }
      }
      .key_button {
        top: -75px;
        height: 60px;
      }
    }
  }
}
@media screen and(min-width:1500.1px) {
  .join {
    top: 80px;
    .content {
      height: 1096px;
      width: 1200px;
      padding: 80px;
      .join_title {
        font-size: 48px;
      }
      .join_tips {
        font-size: 20px;
        top: -20px;
      }
      /deep/ .el-form-item__label {
        width: 100%;
        font-size: 28px;
        padding-left: 10px;
      }
      .form {
        top: -50px;
        /deep/ .el-input__inner {
          height: 60px;
          width: 496px;
        }
        /deep/ .el-form-item {
          padding-bottom: 45px;
        }
      }
      .key_button {
        top: -75px;
        height: 95px;
      }
    }
  }
}
</style>